/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { I18n } from "aws-amplify";
import { RequireNewPassword } from "aws-amplify-react";
import logo from "../../assets/images/logo.png";
import { settings } from "../../configs/settings";

export class CustomRequireNewPassword extends RequireNewPassword {
  showComponent() {
    // eslint-disable-next-line
    const { authData = {} } = this.props;

    return (
      <div className='cardMiddle'>
        <div className='row'>
          <div className='col-6 image-align'>
            <div className='card-image'>
              <div className='col col-12 border-top'>
                <div className='title-logo'>
                  <img
                    src={logo}
                    alt={settings.title_site}
                    className='img-fluid logo'
                  />
                </div>
              </div>
            </div>
          </div>

          <div className='col-6 card-forgotpassword'>
            <div className='row'>
              <div className='col col-8 offset-2'>
                <div className='ResetPassword text-left'>
                  <div className='center'>
                    <input
                      className='input center'
                      id='password'
                      key='password'
                      name='password'
                      onChange={this.handleInputChange}
                      type='password'
                      placeholder='Nova senha'
                    />
                    <button
                      className='btn forgot-password'
                      style={{ margin: "10px auto 30px" }}
                      onClick={this.change}
                    >
                      Alterar
                    </button>
                    <div>
                      <a
                        className='mb-30'
                        onClick={() => super.changeState("signIn")}
                      >
                        {I18n.get("Back to Sign In")}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
