/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:9e82413b-7b70-48c3-9c0a-528c07787c9a",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_fvixNF4BF",
    "aws_user_pools_web_client_id": "2rctl31fpvri9li84ft3b561rs",
    "oauth": {}
};


export default awsmobile;
