import React from 'react'
import { SignIn } from 'aws-amplify-react'
import Auth from '@aws-amplify/auth'
import { I18n } from 'aws-amplify'
import { ConsoleLogger as Logger } from '@aws-amplify/core'
import logo from '../../assets/images/logo.png'
import { settings } from '../../configs/settings'
import { hasAdmin } from '../../util/util'

const logger = new Logger('SignIn')

export class CustomSignIn extends SignIn {
  constructor (props) {
    super(props)
    this._validauthStates = ['signIn', 'signedOut', 'signedUp']
  }

  async signIn (event) {
    // avoid submitting the form
    if (event) {
      event.preventDefault()
    }

    const username = this.getUsernameFromInput() || (sessionStorage.getItem('vtexEmail') ? sessionStorage.getItem('vtexEmail') : '')
    const password = this.inputs.password

    if (!Auth || typeof Auth.signIn !== 'function') {
      throw new Error(
        'No Auth module found, please ensure @aws-amplify/auth is imported'
      )
    }
    this.setState({ loading: true })
    try {
      const user = await Auth.signIn(username, password)
      logger.debug(user)
      if (
        user.challengeName === 'SMS_MFA' ||
				user.challengeName === 'SOFTWARE_TOKEN_MFA'
      ) {
        logger.debug('confirm user with ' + user.challengeName)
        this.changeState('confirmSignIn', user)
      } else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        logger.debug('require new password', user.challengeParam)
        this.changeState('requireNewPassword', user)
      } else if (user.challengeName === 'MFA_SETUP') {
        logger.debug('TOTP setup', user.challengeParam)
        this.changeState('TOTPSetup', user)
      } else if (
        user.challengeName === 'CUSTOM_CHALLENGE' &&
				user.challengeParam &&
				user.challengeParam.trigger === 'true'
      ) {
        logger.debug('custom challenge', user.challengeParam)
        this.changeState('customConfirmSignIn', user)
      } else {
        this.checkContact(user)
      }
    } catch (err) {
      if (err.code === 'UserNotConfirmedException') {
        logger.debug('the user is not confirmed')
        this.changeState('confirmSignUp', { username })
      } else if (err.code === 'PasswordResetRequiredException') {
        logger.debug('the user requires a new password')
        this.changeState('forgotPassword', { username })
      } else {
        this.error(err)
      }
    } finally {
      this.setState({ loading: false })
    }
  }

  showComponent (theme) {
    return (
      <div className='cardMiddle'>
        <div className='row'>
          <div className='col-6 image-align'>
            <div className='card-image'>
              <div className='col col-12 border-top'>
                <div className='title-logo'>
                  <img
                    src={logo}
                    alt={settings.title_site}
                    className='img-fluid logo'
                  />
                </div>
              </div>
            </div>
          </div>

          <div className='col-6'>
            <div className='card-login'>
              <form>

                {hasAdmin() && (
                  <p className='mb-30 text-center'>Painel Administrativo</p>
                )}

                <input
                  className='input center'
                  defaultValue={sessionStorage.getItem('vtexEmail') ? sessionStorage.getItem('vtexEmail') : ''}
                  id='email'
                  key='email'
                  name='email'
                  onChange={this.handleInputChange}
                  type='email'
                  placeholder={I18n.get('Enter your email')}
                />

                <input
                  className='input center'
                  id='password'
                  key='password'
                  name='password'
                  onChange={this.handleInputChange}
                  type='password'
                  placeholder={I18n.get('Enter your password')}
                />

                <div className='center'>
                  <button
                    className='btn'
                    type='submit'
                    onClick={(event) => this.signIn(event)}
                  >
                    {I18n.get('Sign in')}
                  </button>
                </div>

                <div className='center'>
                  <span
                    className='text-indigo cursor-pointer hover:text-indigo-darker'
                    onClick={() => super.changeState('signUp')}
                  >
                    {I18n.get('Create account')}

                  </span>
                  <span className='text'> | </span>
                  <span
                    className='text-indigo cursor-pointer hover:text-indigo-darker'
                    onClick={() => super.changeState('forgotPassword')}
                  >
                    {I18n.get('Reset password')}
                  </span>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
